<template>
  <div>
    <v-dialog v-model="openModal" max-width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <p class="text-center font-weight-bold">DETALLE DE PEDIDO</p>
            </v-col>

            <v-col cols="12" v-show="detalles.length > 0">
              <v-simple-table class="tabla-detalles">
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>CODIGO DE MONTURA</th>
                    <th>NOMBRE DE MONTURA</th>
                    <th>CANTIDAD</th>
                    <th>TIPO DE PEDIDO</th>
                    <th>LUNAS</th>
                    <th>MODO DE ENVIO DE RECETA</th>
                    <th>RECETA SUBIDA</th>
                    <th>RECETA FORMULARIO</th>
                    <!-- <th>COLOR E INTENSIDAD</th>   -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, index) in detalles" :key="index">
                    <td>
                      <p v-text="index + 1"></p>
                    </td>
                    <td>
                      <p v-text="d.producto.codigo"></p>
                    </td>
                    <td>
                      <p v-text="d.producto.nombre"></p>
                    </td>
                    <td>
                      <p v-text="d.cantidad"></p>
                    </td>
                    <td>
                      <p
                        v-if="d.tipoProducto.nombre == 'Montura'"
                        v-text="
                          (d.modelo.idmodelo_producto == 3 ? 'De sol' : d.modelo.nombre) +
                          ' -> ' +
                          d.tipoMedida.nombre
                        "
                      ></p>
                    </td>
                    <td>
                      <!-- <p v-text="d.luna.nombre"></p> -->
                      <p v-text="d.tratamientos_alias+(d.idtratamiento_color ? ' > '+d.tratamientoColor.nombre : '' )"></p>
                    </td>
                    <td>
                      <p v-text="d.metodoEnvioMedida.nombre"></p>
                    </td>
                    <td>
                      <v-btn
                        target="_blank"
                        :href="assetAWS('ventas/recetas_subidas/' + d.medida_ruta)"
                        color="info"
                        v-if="d.medida_ruta != null && d.medida_ruta != ''"
                        text
                      >
                        VER
                      </v-btn>
                    </td>
                    <td>
                      <!--<v-btn
                        @click="datosReceta(d)"
                        color="info"
                        v-if="d.idmedida != null && d.idmedida != ''"
                        text
                      >
                        VER MEDIDA
                      </v-btn>-->
                      <v-btn @click="datosReceta(d)" color="info" text>
                        VER MEDIDA
                      </v-btn>
                    </td>
                    <!-- <td>
                      <p v-if="d.idtratamiento_color" class="text-center">
                        <img :src="assetAWS(d.tratamientoColor.imagen)" style="width: 35px; margin: 10px">
                      </p>
                    </td> -->
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col cols="12" v-show="mostrarReceta">
              <v-simple-table class="tabla-receta">
                <thead>
                  <tr>
                    <th>CODIGO DE MONTURA</th>
                    <th>NOMBRE MONTURA</th>
                    <th>NOMBRE MEDIDA</th>
                    <th class="px-15">OD ESFERA</th>
                    <th class="px-15">OD CILINDRO</th>
                    <th class="px-15">OD EJE</th>
                    <th class="px-15">OI ESFERA</th>
                    <th class="px-15">OI CILINDRO</th>
                    <th class="px-15">OI EJE</th>
                    <th class="px-15">DIP</th>
                    <th class="px-15">ADICIÓN</th>
                    <th class="px-15">FECHA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p v-text="receta.codigoMontura"></p>
                    </td>
                    <td>
                      <p v-text="receta.nombreMontura"></p>
                    </td>
                    <td>
                      <v-text-field class="mt-6" dense label="Nombre" v-model="receta.nombreMedida" ></v-text-field>
                    </td>
                    <td>
                      <v-select 
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.esferas"
                        label="ESFERA"
                        v-model="receta.odEsfera"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.cilindros"
                        label="CILINDRO"
                        v-model="receta.odCilindro"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.ejes"
                        label="EJE"
                        v-model="receta.odEje"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.esferas"
                        label="ESFERA"
                        v-model="receta.oiEsfera"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.cilindros"
                        label="CILINDRO"
                        v-model="receta.oiCilindro"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.ejes"
                        label="EJE"
                        v-model="receta.oiEje"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.dips"
                        label="DIP"
                        v-model="receta.dip"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="resources.adiciones"
                        label="Adición"
                        v-model="receta.adicion"
                      ></v-select>
                    </td>
                    <td>
                      <v-menu :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="mt-6"
                            :value="formatFecha(receta.fechaMedida)"
                            dense
                            clearable
                            label="Fecha"
                            readonly
                            @click:clear="receta.fechaMedida = ''"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="receta.fechaMedida"
                          locale="es-es"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-btn
                :loading="processForm"
                class="float-right mt-2"
                color="success"
                @click="modificarReceta"
              >
                <v-icon left="">mdi-check-bold</v-icon> Modificar
              </v-btn>

              <v-btn
                :loading="processForm"
                class="float-right mt-2 mr-2"
                color="error"
                @click="mostrarReceta = false"
              >
                <v-icon left="">mdi-close</v-icon> Cerrar
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn class="mr-2" color="error" outlined="" @click="openModal = false">
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resources: {
        esferas : [],
        cilindros : [],
        ejes : [],
        adiciones : [],
        dips : [],
        anios : [],
        meses : [],
      },
      openModal: false,
      idventa: "",
      idcliente: "",
      detalles: [],
      receta: {
        idmedida: 0,
        idventa_detalle: 0,
        codigoMontura: "",
        nombreMontura: "",
        nombreMedida: "",
        odEsfera: 0,
        odCilindro: 0,
        odEje: 0,
        oiEsfera: 0,
        oiCilindro: 0,
        oiEje: 0,
        dip: 0,
        adicion: "",
        fechaMedida: "",
      },
      mostrarReceta: false,
      mostrarContenido: false,
      processForm: false,
    };
  },
  props: {
    objModaldetalle: {
      type: Object,
    },
  },
  watch: {
    objModaldetalle(val) {
      this.mostrarReceta = false;
      this.openModal = val.openModal;
      this.idventa = val.idventa;
      this.idcliente = val.idcliente;
      this.detalles = [];
      this.getdetalles();
    },
  },

  methods: {
    getdetalles() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/getDetalles",
        data: {
          idventa: this.idventa,
        },
      })
        .then((response) => {
          const detalles = response.data.detalles;
          this.detalles = detalles;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },

    datosReceta(fila) {
      this.mostrarReceta = true;
      this.receta.idmedida = fila.idmedida;
      this.receta.idventa_detalle = fila.idventa_detalle;
      this.receta.codigoMontura = fila.producto.codigo;
      this.receta.nombreMontura = fila.producto.nombre;
      this.receta.nombreMedida = fila.medida_nombre;
      this.receta.fechaMedida = fila.medida_fecha;

      this.receta.odEsfera = fila.medida_od_esfera;
      this.receta.odCilindro = fila.medida_od_cilindro;
      this.receta.odEje = parseInt(fila.medida_od_eje);

      this.receta.oiEsfera = fila.medida_oi_esfera;
      this.receta.oiCilindro = fila.medida_oi_cilindro;
      this.receta.oiEje = parseInt(fila.medida_oi_eje);

      this.receta.dip = parseInt(fila.medida_dip);
      this.receta.adicion = fila.medida_adicion > 0 ? fila.medida_adicion : fila.medida_adicion;
    },
    valoresRango(inicio, final, contador, texto = "", decimal) {
      let valores = [];
      for (inicio; inicio <= final; inicio += contador) {
        valores.push({
          texto: inicio.toFixed(decimal) + texto,
          valor: inicio.toFixed(decimal),
        });
      }
      return valores;
    },

    formatFecha(fecha) {
      return fecha ? this.$moment(fecha).format("DD/MM/YYYY") : "";
    },

    modificarReceta() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/modificarReceta",
        data: {
          idventa: this.idventa,
          idcliente: this.idcliente,
          receta: this.receta,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$parent.listarRegistros(
              this.$parent.cmbCantidadRegistros,
              this.$parent.paginaActual,
              this.$parent.iptBuscarRegisro
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrio un error al modificar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.processForm = false;
          this.openModal = false;
        });
    },
  },

  created() {
    const esferas    = [];  // -25.00  0.25  +20.00
    const cilindros  = [];  // 0.00    0.25  -8.00
    const ejes       = [];  // 0°      1     180°
    const adiciones  = [];  // +0.75   0.25  +4.00
    const distancias = [];  // 40 mm   1     80 mm

    for (let i = -25; i<=20; i += 0.25) {
        esferas.push({
          valor: i.toFixed(2),
          texto: (i>0 ? '+' : '')+i.toFixed(2)
        });

        if ( -8 <= i  && i < 0 ) {
            cilindros.push({
              valor: i.toFixed(2),
              texto: i.toFixed(2)
            });
        }

        if ( 0.75 <= i && i <= 4) {
            adiciones.push({
              valor: i.toFixed(2),
              texto: (i>0 ? '+' : '')+i.toFixed(2)
            });
        }

    }

    for (let i = 0; i <= 180; i++) {
        ejes.push({
          texto: i,
          valor: i
        });

        if ( 40 <= i && i <= 80) {
          distancias.push({
            texto: i,
            valor: i
          });
        }

    }


    this.resources.esferas    = esferas;
    this.resources.cilindros  = cilindros;
    this.resources.ejes       = ejes;
    this.resources.adiciones  = adiciones;
    this.resources.dips       = distancias;


  },
};
</script>

<style scoped>
.tabla-detalles {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-detalles thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-detalles tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-productos {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-productos thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-productos tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-receta {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-receta thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-receta tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
